.opaqueContainer {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
